import { createUseStyles } from "react-jss";
import { Theme } from "@/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  createReportModal: {
    width: 540,
    // width: "fit-content !important",
    // "& > .ant-modal-content": {
    //   width: 540,
    //   padding: 40,
    // },
  },
  createReportForm: {
    "& input, & input.form-control": {
      width: "100%",
      height: 36,
    },
  },
  formTitle: {
    "&.ant-typography": {
      textAlign: "center",
      fontWeight: 600,
      fontSize: 24,
      textTransform: "none",
      marginBottom: 20,
    },
  },
  formActions: {
    marginTop: 36,
    display: "flex",
    alignItems: "center",
    gap: 20,
    "& > button": {
      flex: 1,
      height: 36,
      fontSize: 16,
    },
  },
}));

export { useStyles };
