import { useState, useEffect, useCallback, useMemo } from "react";
import { Form, Row, Col, message, Select } from "antd";
import type { TablePaginationConfig } from "antd/es/table/interface";
import type { UploadRequestOption } from "rc-upload/lib/interface";
import {
  HomeOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
  FilterOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import debounce from "debounce";
import dayjs, { Dayjs } from "dayjs";

// components
import {
  Button,
  Breadcrumb,
  Input,
  Modal,
  PdfViewer,
  Table,
  Upload,
  FilterDropdown,
  Popconfirm,
  Typography,
} from "@/components";
import type { TableColumnType } from "@/components";

// services
import {
  commonService,
  reportService,
  azureService,
  hookService,
} from "@/services";
import type { UploadFilePayload } from "@/services";

// redux
import { useAppSelector } from "@/redux";
import { selectAccessToken, selectUserInfo } from "@/redux/auth";

// helpers
import { downloadFile, getStorgeHostUrl } from "@/helpers";

// types
import { Option, Report, Pagination } from "@/types";
import { AllReportsFilters } from "@/services/report";
import {
  CustomerOption,
  SubsidiaryOption,
  CreateReportFormValues,
} from "./Reports.types";

// constants
import { DEFAULT_PAGE_SIZE } from "@/components/atoms/table";
import { DEBOUNCE_WAIT, formLayout } from "./Reports.constants";

// styles
import { useStyles } from "./Reports.styles";
import axios from "axios";
import config from "@/config";

const { Title } = Typography;
const { Dragger } = Upload;

const AdminReports = () => {
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([]);
  const [subsidiariesOptions, setSubsidiariesOptions] = useState<
    SubsidiaryOption[]
  >([]);
  const [reportTypeOptions, setReportTypeOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<AllReportsFilters>({});
  const [pagination, setPagination] = useState<Pagination>({
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  });
  const [loadingReports, setLoadingReports] = useState<boolean>(false);
  const [creatingReport, setCreatingReport] = useState<boolean>(false);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Report[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [currentCustomerOption, setCurrentCustomerOption] =
    useState<CustomerOption>();
  const [openPdfPreviewModal, setOpenPdfPreviewModal] =
    useState<boolean>(false);
  const [openCreateReportModal, setOpenCreateReportModal] =
    useState<boolean>(false);

  const [form] = Form.useForm();

  const accessToken = useAppSelector(selectAccessToken);
  const userInfo = useAppSelector(selectUserInfo);

  const styles = useStyles();
  const getReportFilters = useCallback(() => {
    const { dbconnstrs = [] } = filters;
    if (dbconnstrs.length > 0) return filters;
    return {
      ...filters,
      dbconnstrs: customerOptions.map(({ value }) => value),
    };
  }, [filters, customerOptions]);

  const connStr2CustID = useCallback(
    (connStr: string) => {
      const customer = customerOptions.find(({ value }) => value === connStr);
      return customer?.cust_id || "";
    },
    [customerOptions]
  );

  const fetchOptions = async () => {
    setLoadingOptions(true);
    try {
      // load customer options
      const customers = await commonService.getCustomers(accessToken);
      const newCustomerOptions = customers.map(
        ({ cust_id, storage_container_name, dbconnstr, customer_name }) => ({
          cust_id,
          storage_container_name,
          label: customer_name,
          value: dbconnstr,
        })
      );
      setCustomerOptions(newCustomerOptions);

      // load subsidiaries options
      const subsidiaries = await commonService.getSubsidiaries({
        token: accessToken,
      });
      const newSubsidiaries = subsidiaries.map(
        ({ id, org_id, subsidiary_name }) => ({
          org_id,
          label: subsidiary_name,
          value: id,
        })
      );
      setSubsidiariesOptions(newSubsidiaries);

      // load report types
      const reportTypes = await commonService.getReportTypes(accessToken);
      const newReportTypeOptions = reportTypes.map(
        ({ report_type_id, report_type }) => ({
          value: report_type_id,
          label: report_type,
        })
      );
      setReportTypeOptions(newReportTypeOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOptions(false);
    }
  };

  const fetchReports = async () => {
    setLoadingReports(true);
    try {
      // load reports
      const { reports, metadata } = await reportService.getAllReports({
        token: accessToken,
        filters: getReportFilters(),
        ...pagination,
      });
      setDataSource(reports);
      setTotalCount(metadata.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReports(false);
    }
  };

  const onChangeReportNameFilter = debounce((value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      report_name: value,
    }));
  }, DEBOUNCE_WAIT);

  const onChangeUploadedByFilter = debounce((value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      uploaded_by: value,
    }));
  }, DEBOUNCE_WAIT);

  const onDelete = async ({ cust_id, report_id }: Report) => {
    try {
      // delete report & reload table
      await reportService.deleteReport({
        cust_id,
        report_id,
        token: accessToken,
      });
      await fetchReports();

      // notify message
      message.success("A reported was deleted successfully.");
    } catch (error) {
      console.log(error);
      message.error("Failed to delete a report, try again later.");
    }
  };

  const onTableChange = ({ current, pageSize }: TablePaginationConfig) => {
    if (current && pageSize) {
      setPagination({
        offset: (current - 1) * pageSize,
        limit: pageSize,
      });
    }
  };

  const onOpenPDFReportPreview = async (url: string) => {
    try {
      // generate report pdf url
      const { sasToken } = await hookService.getSASToken({
        token: accessToken,
      });
      const reportPdfUrl = `${url}?${sasToken}`;

      // update pdf url
      setPdfUrl(reportPdfUrl);
      setOpenPdfPreviewModal(true);
    } catch (error) {
      console.log(error);
    }
  };
  const onDownloadPDFReport = async (url: string) => {
    try {
      // generate report pdf url
      const { sasToken } = await hookService.getSASToken({
        token: accessToken,
      });
      const reportPdfUrl = `${url}?${sasToken}`;
      const findName = dataSource?.find((item) => item?.url === url);
      let reportName = "";

      if (findName?.report_name) {
        reportName = findName.report_name;
        reportName = reportName.replace(/ /g, "_");
      }
      // update pdf url
      downloadFile(reportPdfUrl, reportName || "report.pdf");
    } catch (error) {
      console.log(error);
    }
  };

  const onCustomRequest = async ({
    file,
    onProgress,
    onSuccess,
    onError,
  }: UploadRequestOption) => {
    try {
      if (!currentCustomerOption) return;

      // get storage container name
      const { storage_container_name } = currentCustomerOption;

      // generate blob name
      const fileName = (file as File).name;
      const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
      const blobName = `${dayjs().valueOf()}.${extension}`;

      // upload blob to azure storage
      const payload: UploadFilePayload = {
        file: file as Blob,
        blobName,
        containerName: storage_container_name,
        token: accessToken,
        onProgress: (percent) => onProgress?.({ percent }),
      };
      await azureService.uploadFile(payload);
      onSuccess?.(null);

      // update url field value
      form.setFieldsValue({
        url: `${getStorgeHostUrl()}/${storage_container_name}/${blobName}`,
      });
    } catch (error: any) {
      onError?.(error);
      message.error("Failed to upload a file, try again!");
    }
  };

  const sendMessageToAllUsers = async () => {
    try {
      console.log(
        "sendMessageToAllUserssendMessageToAllUserssendMessageToAllUsers"
      );
      const currentdate = new Date();
      const dataToSave = {
        title: "New report has been uploaded",
        body: "A new report has been uploaded to the secure portal.",
      };

      const url = `${config.api.baseUrl}/upload/sendNotification`;
      const response = await axios.post(
        url,
        {
          data: dataToSave,
          timestamp: currentdate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Alert added successfully!", response.data, response.status);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  const onSubmitCreateReportForm = async (values: CreateReportFormValues) => {
    setCreatingReport(true);

    try {
      await reportService.createReport({
        ...values,
        cust_id: connStr2CustID(values.cust_id),
        upload_date_time: dayjs().toISOString(),
        uploaded_by: userInfo?.email,
        token: accessToken,
      });
      await fetchReports();

      // reset form & close modal
      form.resetFields();
      setOpenCreateReportModal(false);

      // notify message
      message.success("A reported was created successfully.");
      sendMessageToAllUsers();
    } catch (error) {
      console.log(error);
      message.error("Failed to create a report, try again later.");
    } finally {
      setCreatingReport(false);
    }
  };

  const onChangeReportCustomer = (newValue: string) => {
    const customer = customerOptions.find(({ value }) => value === newValue);
    setCurrentCustomerOption(customer);
    form.resetFields(["subsidiary_id", "url"]);
  };

  const onResetCreateReportForm = () => {
    form.resetFields();
  };

  const onCloseCreateReportModal = () => {
    setOpenCreateReportModal(false);
    form.resetFields();
  };

  useEffect(() => {
    if (accessToken) {
      fetchOptions();
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      fetchReports();
    }
  }, [accessToken, customerOptions, filters, pagination]);

  const filteredSubsidiariesOptions = useMemo(
    () =>
      subsidiariesOptions.filter(
        ({ org_id }) => org_id === currentCustomerOption?.cust_id
      ),
    [subsidiariesOptions, currentCustomerOption]
  );

  const columns: TableColumnType[] = [
    {
      title: "Customer",
      dataIndex: "cust_id",
      filterIcon: <FilterOutlined />,
      filterDropdown: (
        <FilterDropdown
          variant="multi-select"
          loading={loadingOptions}
          options={customerOptions}
          onChange={(value) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              dbconnstrs: value,
            }));
          }}
        />
      ),
      render: (value) => {
        return customerOptions.find((opt) => opt.cust_id === value)?.label;
      },
    },
    {
      title: "Subsidiary",
      dataIndex: "subsidiary_id",
      filterIcon: <FilterOutlined />,
      filterDropdown: (
        <FilterDropdown
          variant="multi-select"
          loading={loadingOptions}
          options={subsidiariesOptions}
          onChange={(value) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              subsidiaries: value,
            }));
          }}
        />
      ),
      render: (value) => {
        return subsidiariesOptions.find((opt) => opt.value === value)?.label;
      },
    },
    {
      title: "Report Name",
      dataIndex: "report_name",
      filterIcon: <FilterOutlined />,
      filterDropdown: (
        <FilterDropdown
          variant="input"
          onChange={(e) => onChangeReportNameFilter(e.target.value)}
        />
      ),
    },
    {
      title: "Report Type",
      dataIndex: "report_type",
      width: 320,
      filterIcon: <FilterOutlined />,
      filterDropdown: (
        <FilterDropdown
          variant="multi-select"
          loading={loadingOptions}
          options={reportTypeOptions}
          onChange={(value) =>
            setFilters((prevFilters) => ({
              ...prevFilters,
              report_types: value,
            }))
          }
        />
      ),
      render: (value) => {
        return reportTypeOptions.find((opt) => opt.value === value)?.label;
      },
    },
    {
      title: "Upload Date",
      dataIndex: "upload_date_time",
      width: 200,
      filterIcon: <FilterOutlined />,
      filterDropdown: (
        <FilterDropdown
          variant="range-picker"
          onChange={(dates: Dayjs[]) =>
            setFilters((prevFilters) => ({
              ...prevFilters,
              upload_date_range: dates?.map((date) => date.toISOString()),
            }))
          }
        />
      ),
      render: (value) => dayjs(value).format("MM/DD/YYYY"),
    },
    {
      title: "Uploaded By",
      dataIndex: "uploaded_by",
      width: 200,
      filterIcon: <FilterOutlined />,
      filterDropdown: (
        <FilterDropdown
          variant="input"
          onChange={(e) => onChangeUploadedByFilter(e.target.value)}
        />
      ),
    },
    {
      title: "Report Pdf",
      dataIndex: "url",
      align: "center",
      width: 240,
      render: (value) => (
        <div className={styles.reportPdfCol}>
          <Button
            icon={<EyeOutlined />}
            onClick={() => onOpenPDFReportPreview(value)}
          />
          <Button
            icon={<DownloadOutlined />}
            onClick={() => onDownloadPDFReport(value)}
          />
        </div>
      ),
    },
    {
      width: 120,
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title="Delete a report"
          description="Are you sure to delete this report?"
          placement="topRight"
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete(record as Report)}
        >
          <Button shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const breadcrumbItems = [
    {
      href: "/dashboard",
      title: <HomeOutlined />,
    },
    { title: "Admin" },
    { title: "Reports" },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Breadcrumb items={breadcrumbItems} linkRouter />
        <Button
          className={styles.createReportBtn}
          disabled={loadingReports}
          // onClick={() => sendMessageToAllUsers()}
          onClick={() => setOpenCreateReportModal(true)}
        >
          Create a report
        </Button>
      </div>
      <div className={styles.content}>
        <Table
          className={styles.reportsTable}
          rowKey="report_id"
          bordered={false}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            total: totalCount,
            showTotal: (total) => `Total ${total} Reports`,
          }}
          loading={loadingReports}
          onChange={onTableChange}
        />
      </div>

      {/* Create Report Modal */}
      <Modal
        className={styles.createReportModal}
        open={openCreateReportModal}
        onCancel={onCloseCreateReportModal}
        footer={null}
      >
        <Form
          {...formLayout}
          className={styles.createReportForm}
          form={form}
          name="create-report"
          onFinish={onSubmitCreateReportForm}
        >
          <Title className={styles.formTitle}>Create a report</Title>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="report_name"
                label="Report Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="report_type"
                label="Report Type"
                rules={[{ required: true }]}
              >
                <Select options={reportTypeOptions} showSearch allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="cust_id"
                label="Customer"
                rules={[{ required: true }]}
              >
                <Select
                  options={customerOptions}
                  onChange={onChangeReportCustomer}
                  showSearch
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          {filteredSubsidiariesOptions.length > 0 && (
            <Row gutter={30}>
              <Col sm={24}>
                <Form.Item name="subsidiary_id" label="Subsidiary">
                  <Select
                    options={filteredSubsidiariesOptions}
                    showSearch
                    allowClear
                    disabled={!currentCustomerOption}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={30}>
            <Col sm={24}>
              <Form.Item
                name="url"
                label="Report File"
                rules={[{ required: true }]}
              >
                <Dragger
                  className={styles.reportFileDragger}
                  multiple={false}
                  maxCount={1}
                  accept=".pdf,.doc,.docx,.csv"
                  customRequest={onCustomRequest}
                  disabled={!currentCustomerOption}
                >
                  <p className="ant-upload-drag-icon">
                    <FilePdfOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Drag and drop your file here or click to browse
                  </p>
                  <p className="ant-upload-hint">
                    Support file type: .pdf .docs .csv
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.formActions}>
            <Button type="primary" htmlType="submit" loading={creatingReport}>
              Submit
            </Button>
            <Button htmlType="button" onClick={onResetCreateReportForm}>
              Reset
            </Button>
          </div>
        </Form>
      </Modal>

      {/* Pdf Preview Modal */}
      <Modal
        className={styles.pdfPreviewModal}
        open={openPdfPreviewModal}
        onCancel={() => setOpenPdfPreviewModal(false)}
        footer={null}
      >
        <PdfViewer file={pdfUrl} />
      </Modal>
    </div>
  );
};

export { AdminReports };
