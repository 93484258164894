import { Col, Form, message, Row, Select } from "antd";
import { useStyles } from "./Messages.styles";

// components
import { Button, Input, Modal, Typography } from "@/components";
import { useAppSelector } from "@/redux";
import { selectAccessToken } from "@/redux/auth";
import { mgmtServices } from "@/services/Mgmt/MgmtServices";
import { Option } from "@/types";
import { useEffect, useState } from "react";
import { formLayout } from "./Messages.constants";
import { CreateReportFormValues } from "./Messages.types";
import config from "@/config";
import axios from "axios";

const AdminMessages = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: () => void;
}) => {
  const styles = useStyles();
  const [form] = Form.useForm();
  const { Title } = Typography;

  const accessToken = useAppSelector(selectAccessToken);
  const [customerTypeOptions, setCustomerTypeOptions] = useState<
    Array<Option & { id?: string }>
  >([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [creatingReport, setCreatingReport] = useState<boolean>(false);

  const fetchOptions = async () => {
    setLoadingOptions(true);
    try {
      const token = await mgmtServices.getMgmtToken();
      const access = token?.access_token;
      if (access) {
        const organizational = await mgmtServices.getOrganizational(access);
        // Map the organizational data to customer type options
        const newOrganizationalOptions = organizational.map(
          ({ display_name, name, id }) => ({
            id,
            label: display_name, // Ensure display_name exists in OrganizationalItem
            value: name,
          })
        );

        // Set customer type options
        setCustomerTypeOptions(newOrganizationalOptions);
      } else {
        console.error("Access token not found in the response");
      }
    } catch (error) {
      console.log("Error from getOrganizations:", error);
    } finally {
      setLoadingOptions(false);
    }
  };
  useEffect(() => {
    if (accessToken) {
      fetchOptions();
    }
  }, [accessToken]);
  const onResetCreateReportForm = () => {
    form.resetFields();
  };

  const onSubmitCreateReportForm = async (values: CreateReportFormValues) => {
    setCreatingReport(true);
    const orgId = customerTypeOptions.find(
      (item) => item.value === values?.select_customer
    )?.id;
    const currentDate = new Date();
    const currentMonthPlusOne = currentDate.getMonth() + 1;
    const datetime = `${currentDate.getDate()}/${currentMonthPlusOne}/${currentDate.getFullYear()} @ ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    // Step 1: Parse the input date string
    const [datePart, timePart] = datetime.split(" @ ");

    if (!datePart || !timePart) return;
    const datePartArray = datePart.split("/") || [];
    const timePartArray = timePart.split(":") || [];

    const [day, month, year] = datePartArray.map(Number);
    const [hours, minutes, seconds] = timePartArray.map(Number);
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    const timestamp = date.toISOString();

    try {
      const dataToSave = {
        description: values?.message_descriptions,
        recommendations: values?.message_recomendations,
        subject: values?.message_subject,
        tags: values?.message_tags,
        title: values?.message_title,
        custId: orgId,
      };
      const url = `${config.api.baseUrl}/upload/addAlert`;
      const response = await axios.post(
        url,
        { data: dataToSave, timestamp },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Alert added successfully!", response.data, response.status);

      form.resetFields();

      message.success("Message sent successfully.");
    } catch (error) {
      console.log(error);
      message.error("Failed to send a message, try again later.");
    } finally {
      setCreatingReport(false);
    }
  };
  return (
    <Modal
      className={styles.createReportModal}
      open={isOpen}
      onCancel={setIsOpen}
      footer={null}
    >
      <Form
        {...formLayout}
        className={styles.createReportForm}
        form={form}
        name="create-report"
        onFinish={onSubmitCreateReportForm}
      >
        <Title className={styles.formTitle}>Send a Message</Title>

        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="select_customer"
              label="Select Customer"
              rules={[{ required: true }]}
            >
              <Select options={customerTypeOptions} showSearch allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="message_title"
              label="Message Title"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="message_tags"
              label="Message Tags"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="message_subject"
              label="Message Subject"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="message_recomendations"
              label="Message Recomendation"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="message_descriptions"
              label="Message Body"
              rules={[{ required: true }]}
            >
              <Input variant="textarea" style={{ minHeight: 120 }} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.formActions}>
          <Button type="primary" htmlType="submit" loading={creatingReport}>
            Submit
          </Button>
          <Button htmlType="button" onClick={onResetCreateReportForm}>
            Reset
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export { AdminMessages };
